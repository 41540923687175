import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { ScrollRestoration } from 'react-router-dom';
import {
	FooterThreeColumn,
	NavigationBar,
	PageTitle,
	CartBar,
	ProductBlock,
	ProductSpotlightBlock,
} from '@ebbo/pulse-uikit';

const RewardsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Rewards" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<ProductBlock />
			<ProductSpotlightBlock />
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedRewardsPage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <RewardsPage ref={ref} />),
);

export default RewardsPage;
