import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { ScrollRestoration, useParams } from 'react-router-dom';
import {
	FooterThreeColumn,
	NavigationBar,
	CartBar,
	PageTitle,
	ProductDetailBlock,
} from '@ebbo/pulse-uikit';

const ProductDetailPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const params = useParams();

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Product Detail" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<ProductDetailBlock productId={params.productId} />
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedProductDetailPage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <ProductDetailPage ref={ref} />),
);
export default ProductDetailPage;
