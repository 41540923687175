import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Text,
	ToastId,
	useDisclosure,
	useToast,
	useToken,
	VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { NavLink, ScrollRestoration, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { PageTitle, CartBar } from '@ebbo/pulse-uikit';
import {
	FooterThreeColumn,
	NavigationBar,
	useApiManager,
	useAuthManager,
	useSharedAppStates,
	AuthValidationScheme,
	IAuthForm,
} from '@ebbo/pulse-uikit';

const AuthPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user, setUser } = useSharedAppStates();
	const { isOpen: showPassword, onToggle: onToggleShowPassword } = useDisclosure();
	const toast = useToast();
	const toastIdRef = React.useRef<ToastId>();
	const [loading, setLoading] = useState<boolean>(false);
	const { useLogin: authLogin } = useAuthManager();
	const { useLogin: apiLogin } = useApiManager();
	const navigate = useNavigate();
	const { mutateAsync: doAuthLogin } = authLogin();
	const { mutateAsync: doApiLogin } = apiLogin();
	const maxWidth = useToken('breakpoints', 'xl');

	const formik = useFormik<IAuthForm>({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: AuthValidationScheme,
		onSubmit: (values) => {
			if (values.email && values.password) {
				setLoading(true);
				toastIdRef.current = toast({
					title: 'Logging In...',
					status: 'loading',
					id: 'login',
					duration: null,
				});
				doAuthLogin({ userName: values.email, password: values.password })
					.then((res) => {
						// console.log('Login Successful');
						doApiLogin({ accountNumber: res.burstId })
							.then((v) => {
								// console.log(v);
								setUser({
									cognitoUser: res.cognitoUser,
									burstId: res.burstId,
									burstProfile: v,
								});

								if (toastIdRef.current) {
									toast.update(toastIdRef.current, {
										title: 'Login Successful!',
										status: 'success',
										isClosable: false,
										duration: 500,
									});
								}
								void navigate('/earn-points');
								setLoading(false);
								formik.resetForm();
							})
							.catch((e: unknown) => {
								if (toastIdRef.current) {
									toast.update(toastIdRef.current, {
										title: 'Profile Error',
										description: JSON.stringify(e),
										status: 'error',
										isClosable: true,
										duration: null,
									});
								}
								setLoading(false);
							});
					})
					.catch((e: unknown) => {
						console.error('Login Error');
						if (toastIdRef.current) {
							toast.update(toastIdRef.current, {
								title: 'Login Error',
								description: (e as Error).message || JSON.stringify(e),
								status: 'error',
								isClosable: true,
								duration: null,
							});
						}
						setLoading(false);
					});
			}
		},
	});

	if (user) {
		return (
			<Flex
				textAlign="center"
				fontSize="xl"
				ref={ref}
				direction={'column'}
				justifyContent={'center'}
			>
				{/* TODO Prefix Support? */}
				<PageTitle title="Login" />
				<ScrollRestoration />
				<CartBar />
				<NavigationBar />
				<VStack gap={4} py={4} maxW={maxWidth}>
					<Heading>Hi {user.burstProfile.firstName}! You're already signed in!</Heading>
				</VStack>
				<FooterThreeColumn />
			</Flex>
		);
	}

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Login" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<Center>
				<VStack maxW={maxWidth} flex={1}>
					<VStack maxW={'5xl'} width={'100%'} gap={6}>
						<Heading size={{ base: 'md' }}>Welcome back to Peak Rewards!</Heading>
						<VStack maxW={'lg'} width={'100%'} gap={4}>
							<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input
									id="email"
									type="email"
									name="email"
									required
									value={formik.values.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.errors.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>}
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.password)}>
								<FormLabel htmlFor="password">Password</FormLabel>
								<InputGroup>
									<InputRightElement>
										<IconButton
											variant="text"
											aria-label={showPassword ? 'Mask password' : 'Reveal password'}
											icon={showPassword ? <HiEyeOff /> : <HiEye />}
											onClick={onToggleShowPassword}
										/>
									</InputRightElement>
									<Input
										id="password"
										name="password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="current-password"
										required
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</InputGroup>
								{formik.errors.password && (
									<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
								)}
							</FormControl>
							<HStack justify="space-between" alignItems={'center'} alignSelf={'stretch'}>
								<Link as={NavLink} to={'/forgot-password'} variant="text" size="sm" fontSize={'sm'}>
									Forgot password?
								</Link>
								<Button
									colorScheme="secondary"
									textTransform={'uppercase'}
									onClick={() => {
										toast.closeAll();
										formik.handleSubmit();
									}}
									isLoading={loading}
								>
									Sign in
								</Button>
							</HStack>
						</VStack>
						<VStack justifyContent={'flex-start'} alignItems={'flex-start'} width={'100%'}>
							<Text>DON&rsquo;T HAVE AN ACCOUNT?</Text>
							<HStack>
								<Link
									variant="text"
									onClick={() => {
										formik.resetForm();
										void navigate('/join');
									}}
									textDecoration={'underline'}
								>
									Register now
								</Link>
								<Text> for Peak Rewards today!</Text>
							</HStack>
						</VStack>
					</VStack>
				</VStack>
			</Center>
			{/* <Box
				maxW={maxWidth}
				mx="auto"
				px={{ base: '4', md: '8', lg: '12' }}
				py={{ base: '6', md: '8', lg: '12' }}
			>
				<Stack spacing="8" maxW={'5xl'}>
					<Stack spacing={{ base: '2', md: '3' }}>
						<Heading size={{ base: 'xs', md: 'sm' }}>Welcome back to Peak Rewards!</Heading>
						<Stack spacing="5">
							<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input
									id="email"
									type="email"
									name="email"
									required
									value={formik.values.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.errors.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>}
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.password)}>
								<FormLabel htmlFor="password">Password</FormLabel>
								<InputGroup>
									<InputRightElement>
										<IconButton
											variant="text"
											aria-label={showPassword ? 'Mask password' : 'Reveal password'}
											icon={showPassword ? <HiEyeOff /> : <HiEye />}
											onClick={onToggleShowPassword}
										/>
									</InputRightElement>
									<Input
										id="password"
										name="password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="current-password"
										required
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</InputGroup>
								{formik.errors.password && (
									<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
								)}
							</FormControl>
							<HStack justify="space-between" alignItems={'flex-start'}>
								<Link as={NavLink} to={'/forgot-password'} variant="text" size="sm" fontSize={'sm'}>
									Forgot password?
								</Link>
								<Button
									textTransform={'uppercase'}
									onClick={() => {
										toast.closeAll();
										formik.handleSubmit();
									}}
									isLoading={loading}
								>
									Sign in
								</Button>
							</HStack>
							<VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
								<Text>DON&rsquo;T HAVE AN ACCOUNT?</Text>
								<HStack>
									<Link
										variant="text"
										onClick={() => {
											formik.resetForm();
											navigate('/join');
										}}
										textDecoration={'underline'}
									>
										Register now
									</Link>
									<Text> for Peak Rewards today!</Text>
								</HStack>
							</VStack>
						</Stack>
					</Stack>
				</Stack>
			</Box> */}
			<FooterThreeColumn />
		</Box>
	);
});
export const AnimatedAuthPage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <AuthPage ref={ref} />),
);
export default AuthPage;
