import {
	useSharedAppConfiguration,
	useSharedAppStates,
	useAuthManager,
	expireCookie,
} from '@ebbo/pulse-uikit';
import { useEffect } from 'react';

const AuthHandler: React.FC = () => {
	const { appConfiguration } = useSharedAppConfiguration();
	const { user, setUser } = useSharedAppStates();
	const { useValidate: authValidate } = useAuthManager();
	const { mutateAsync: validateAuthAsync } = authValidate();

	useEffect(() => {
		// console.log('AuthHandler::useEffect');
		// console.log('AuthHandler::userChanged::user', user);
		// console.log('AuthHandler::userChanged::appconfig', appConfiguration);
		// expireCookie('accessToken');
		if (!appConfiguration) {
			return;
		}
		if (user !== undefined) {
			return;
		}
		validateAuthAsync(undefined)
			// .then((v: boolean) => {
			// 	console.log(v);
			// })
			.catch((e: unknown) => {
				console.log(e);
				expireCookie('accessToken');
				setUser(undefined);
				window.location.assign('/');
			});
		// eslint-disable-next-line
	}, [user, appConfiguration]);

	return null;
};
export default AuthHandler;
