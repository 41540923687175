import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import {
	FooterThreeColumn,
	HeroImageLeft,
	HeroImageRight,
	NavigationBar,
	PageHeaderCentered,
	PerksThreeColumn,
	useSharedAppStates,
	IEarningAction,
	EarningGallery,
	EarningGrid,
	PollModal,
	ProfileModal,
	ReviewModal,
	VideoPlayer,
	CartBar,
	PageTitle,
} from '@ebbo/pulse-uikit';

const EarnPointsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user } = useSharedAppStates();
	const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);
	const [showPoll, setShowPoll] = useState<boolean>(false);
	const [showProfileForm, setShowProfileForm] = useState<boolean>(false);
	const [showReivewForm, setShowReviewForm] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<IEarningAction>();
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Earn Points" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<HeroImageRight
				marginTop="40px"
				heroImageUrl="/media/images/MC_Image_620x425.jpg"
				heroImageAlt="Mountain Clothing"
				contentBgColor="secondary.500"
			/>
			{!user && (
				<>
					<HeroImageLeft
						marginTop="40px"
						heroImageUrl="https://burst-staging.ebbo.com/media/wysiwyg/home/GrandCanyon_Img.png"
						heroImageAlt="The North Face"
						contentBgColor="#20513f"
						showSpecialBadge
					/>
					<EarningGallery
						setSelectedAction={setSelectedAction}
						setShowVideoPlayer={setShowVideoPlayer}
						setShowPoll={setShowPoll}
						setShowProfileForm={setShowProfileForm}
						setShowReviewForm={setShowReviewForm}
					/>
					<PageHeaderCentered />
					<PerksThreeColumn />
				</>
			)}
			{user && (
				<EarningGrid
					setSelectedAction={setSelectedAction}
					setShowVideoPlayer={setShowVideoPlayer}
					setShowPoll={setShowPoll}
					setShowProfileForm={setShowProfileForm}
					setShowReviewForm={setShowReviewForm}
				/>
			)}
			<FooterThreeColumn />
			<VideoPlayer
				showModal={showVideoPlayer}
				setShowModal={setShowVideoPlayer}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
			<PollModal
				showModal={showPoll}
				setShowModal={setShowPoll}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
			<ProfileModal
				showModal={showProfileForm}
				setShowModal={setShowProfileForm}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
			{/* TODO Reviews PULSE-75 */}
			<ReviewModal
				showModal={showReivewForm}
				setShowModal={setShowReviewForm}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
		</Box>
	);
});

export const AnimatedEarnPointsPage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <EarnPointsPage ref={ref} />),
);

export default EarnPointsPage;
