import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { ScrollRestoration } from 'react-router-dom';
import {
	FooterThreeColumn,
	HeroImageBackground,
	MapTrail,
	NavigationBar,
	PageHeaderCenteredOnAccent,
	PerksThreeColumn,
	TierBenefits,
	useSharedAppConfiguration,
	CartBar,
	PageTitle,
	RandomProductSlider,
} from '@ebbo/pulse-uikit';

const HomePage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { appConfiguration } = useSharedAppConfiguration();
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Home" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<HeroImageBackground
				title={appConfiguration?.content?.HomePage.HeroImageBackground.title ?? 'Test Title'}
				text={appConfiguration?.content?.HomePage.HeroImageBackground.text ?? 'Test Text'}
				backgroundImageSrc={
					appConfiguration?.content?.HomePage.HeroImageBackground.backgroundImageSrc ??
					'https://images.unsplash.com/photo-1590650153855-d9e808231d41?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2250&q=80'
				}
				joinButtonText={
					appConfiguration?.content?.HomePage.HeroImageBackground.joinButtonText ?? 'Test Join'
				}
				existText={
					appConfiguration?.content?.HomePage.HeroImageBackground.existText ?? 'Test Exist'
				}
			/>
			<PageHeaderCenteredOnAccent
				text={appConfiguration?.content?.HomePage.PageHeaderCenteredOnAccent.text ?? 'Test Text'}
				heading={
					appConfiguration?.content?.HomePage.PageHeaderCenteredOnAccent.heading ?? 'Test Heading'
				}
				logo={
					appConfiguration?.content?.HomePage.PageHeaderCenteredOnAccent.logo ??
					'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Mountain_img_blue.png'
				}
			/>
			<PerksThreeColumn />
			{/* <PageHeaderCentered /> */}
			<RandomProductSlider />
			<TierBenefits backgroundImageSrc="/media/images/chart-bg.webp" />
			<MapTrail />
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedHome = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <HomePage ref={ref} />),
);

export default HomePage;
