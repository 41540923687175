import React from 'react';
import { motion } from 'framer-motion';
import {
	Box,
	Heading,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	VStack,
	useColorModeValue,
} from '@chakra-ui/react';
import { ScrollRestoration } from 'react-router-dom';
// import AccountHistoryGiftCard from '../components/user/AccountHistoryGiftCard';
import {
	NavigationBar,
	FooterThreeColumn,
	CartBar,
	PageTitle,
	AccountDetails,
	AccountHistoryEarning,
	AccountHistoryReferral,
	AccountAddresses,
	AccountHistoryRedemption,
	AccountEarning,
} from '@ebbo/pulse-uikit';

const UserProfilePage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	return (
		<Box
			textAlign="center"
			fontSize="xl"
			ref={ref}
			backgroundColor={useColorModeValue('#EFE5D9', 'secondary.500')}
		>
			{/* TODO Prefix Support? */}
			<PageTitle title="My Account" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<VStack maxW="7xl" justifyContent={'center'} mx="auto" gap={5} mb={'2rem'}>
				<Heading as="h1" textTransform="uppercase" mt={'2rem'} alignSelf={'stretch'}>
					MY ACCOUNT
				</Heading>
				<Tabs variant="line" colorScheme="primary.500" isLazy isFitted alignSelf={'stretch'}>
					<TabList>
						<Tab>MY DETAILS</Tab>
						<Tab>ACTIVITY</Tab>
						<Tab>MY REFERRALS</Tab>
					</TabList>
					<TabPanels>
						{/* MY DETAILS TAB */}
						<TabPanel p={0}>
							<VStack>
								<AccountDetails />
								<AccountAddresses />
								<AccountEarning />
							</VStack>
						</TabPanel>
						{/* activity tab */}
						<TabPanel p={0}>
							<VStack>
								<AccountHistoryEarning />
								<AccountHistoryRedemption />
								{/* <AccountHistoryGiftCard /> */}
							</VStack>
						</TabPanel>
						{/* referrals tab */}
						<TabPanel p={0}>
							<VStack>
								<AccountHistoryReferral />
							</VStack>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</VStack>
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedUserProfilePage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <UserProfilePage ref={ref} />),
);

export default UserProfilePage;
