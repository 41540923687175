import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Progress,
	Stack,
	Text,
	ToastId,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ScrollRestoration } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HiEyeOff, HiEye } from 'react-icons/hi';
import { passwordStrength } from 'check-password-strength';
import {
	SignInButton,
	NavigationBar,
	FooterThreeColumn,
	useAuthManager,
	useSharedAppStates,
	CartBar,
	PageTitle,
} from '@ebbo/pulse-uikit';

const ValidationScheme = yup.object({
	email: yup.string().email('Must be a valid Email Address!').required('Email is Required!'),
});

const ConfirmValidationScheme = yup.object({
	confirmationCode: yup.string().required('Confirmation Code is Required'),
	password: yup
		.string()
		.required('Password is Required')
		.min(8, 'Password is too short')
		.max(25, 'Password is too long')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
			'Password must contain at least one uppercase, one lowercase, one special character, and one number with a minimum of eight characters',
		),
	confirmPassword: yup
		.string()
		.required('Confirm Password is Required')
		.oneOf([yup.ref('password')], 'Passwords must match'),
});

const ForgotPasswordPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user } = useSharedAppStates();
	const toast = useToast();
	const toastIdRef = React.useRef<ToastId>();
	const { useForgotPassword, useConfirmNewPassword } = useAuthManager();
	const { mutateAsync, isPending } = useForgotPassword();
	const { mutateAsync: setNewPassword, isPending: setNewPasswordLoading } = useConfirmNewPassword();
	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [showSuccess, setShowSuccess] = useState<boolean>(false);
	const { isOpen: showPassword, onToggle: onToggleShowPassword } = useDisclosure();
	const { isOpen: showConfirmPassword, onToggle: onToggleShowConfirmPassword } = useDisclosure();
	const [pwStr, setPwStr] = useState<number>(0);

	const formik = useFormik<{ email: string }>({
		initialValues: {
			email: '',
		},
		validationSchema: ValidationScheme,
		onSubmit: (values) => {
			// console.log('Formik Submit:', values);
			toastIdRef.current = toast({
				title: 'Finding You...',
				status: 'loading',
				id: 'reset',
				duration: null,
			});
			mutateAsync({ userName: values.email })
				.then(() => {
					// console.log('Reset Initiated:', res);
					setShowConfirm(true);
					toast.closeAll();
				})
				.catch((e: unknown) => {
					console.error('Reset Error');
					if (toastIdRef.current) {
						toast.update(toastIdRef.current, {
							title: 'Error',
							description: (e as Error).message || JSON.stringify(e),
							status: 'error',
							isClosable: true,
							duration: null,
						});
					}
				});
		},
	});

	const formikConfirm = useFormik<{
		email: string;
		confirmationCode: string;
		password: string;
		confirmPassword: string;
	}>({
		initialValues: {
			email: '',
			confirmationCode: '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: ConfirmValidationScheme,
		onSubmit: (values) => {
			// console.log('Formik Submit:', values);

			toastIdRef.current = toast({
				title: 'Resetting Password',
				status: 'loading',
				id: 'reset',
				duration: null,
			});
			setNewPassword({
				userName: formik.values.email,
				confirmationCode: values.confirmationCode,
				newPassword: values.password,
			})
				.then(() => {
					// console.log('Reset Successful:', res);
					setShowConfirm(false);
					setShowSuccess(true);
					toast.closeAll();
				})
				.catch((e: unknown) => {
					console.error('Reset Confirm Error');
					if (toastIdRef.current) {
						toast.update(toastIdRef.current, {
							title: 'Error',
							description: (e as Error).message || JSON.stringify(e),
							status: 'error',
							isClosable: true,
							duration: null,
						});
					}
				});
		},
	});

	// Calculate Password Strength
	useEffect(() => {
		const strRes = passwordStrength(
			formikConfirm.values.password ? formikConfirm.values.password : '',
			[
				{
					id: 0,
					value: 'Too weak',
					minDiversity: 0,
					minLength: 0,
				},
				{
					id: 1,
					value: 'Weak',
					minDiversity: 2,
					minLength: 6,
				},
				{
					id: 2,
					value: 'Medium',
					minDiversity: 4,
					minLength: 8,
				},
				{
					id: 3,
					value: 'Strong',
					minDiversity: 4,
					minLength: 13,
				},
			],
		);
		// console.log(strRes);
		let val = 0;
		switch (strRes.id) {
			case 1:
				val = 33;
				break;
			case 2:
				val = 66;
				break;
			case 3:
				val = 100;
				break;
			default:
				val = 0;
				break;
		}
		// console.log(val);
		setPwStr(val);
	}, [formikConfirm.values.password]);

	if (user) {
		return (
			<Flex
				textAlign="center"
				fontSize="xl"
				ref={ref}
				direction={'column'}
				justifyContent={'center'}
			>
				{/* TODO Prefix Support? */}
				<PageTitle title="Forgot Password" />
				<ScrollRestoration />
				<CartBar />
				<NavigationBar />
				<VStack gap={4} py={4}>
					<Heading>Hi {user.burstProfile.firstName}! You're already signed in!</Heading>
				</VStack>
				<FooterThreeColumn />
			</Flex>
		);
	}

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Forgot Password" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			{!showConfirm && !showSuccess && (
				<Box
					maxW="7xl"
					mx="auto"
					px={{ base: '4', md: '8', lg: '12' }}
					py={{ base: '6', md: '8', lg: '12' }}
				>
					<Stack spacing="8">
						<Stack spacing={{ base: '2', md: '3' }}>
							<Heading size={{ base: 'xs', md: 'sm' }}>Forgot Password</Heading>
							<Stack spacing="5">
								<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
									<FormLabel htmlFor="email">Email</FormLabel>
									<Input
										id="email"
										type="email"
										name="email"
										required
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.errors.email && (
										<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
									)}
								</FormControl>
								{/* <FormControl isRequired isInvalid={Boolean(formik.errors.password)}>
								<FormLabel htmlFor="password">Password</FormLabel>
								<InputGroup>
									<InputRightElement>
										<IconButton
											variant="text"
											aria-label={showPassword ? 'Mask password' : 'Reveal password'}
											icon={showPassword ? <HiEyeOff /> : <HiEye />}
											onClick={onToggleShowPassword}
										/>
									</InputRightElement>
									<Input
										id="password"
										name="password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="current-password"
										required
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</InputGroup>
								{formik.errors.password && (
									<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
								)}
							</FormControl>
							<HStack justify="space-between" alignItems={'flex-start'}>
								<Link variant="text" size="sm" fontSize={'sm'}>
									Forgot password?
								</Link>
								<Button
									textTransform={'uppercase'}
									onClick={() => {
										toast.closeAll();
										formik.handleSubmit();
									}}
									isLoading={loading}
								>
									Sign in
								</Button>
							</HStack> */}
								<HStack justify="flex-end">
									<Button
										textTransform={'uppercase'}
										onClick={() => {
											toast.closeAll();
											formik.handleSubmit();
										}}
										isLoading={isPending}
									>
										Reset
									</Button>
								</HStack>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			)}
			{showConfirm && !showSuccess && (
				<Box
					maxW="7xl"
					mx="auto"
					px={{ base: '4', md: '8', lg: '12' }}
					py={{ base: '6', md: '8', lg: '12' }}
				>
					<Stack spacing="8">
						<Stack spacing={{ base: '2', md: '3' }}>
							<Heading as={'h1'} fontSize={{ base: 'sm', md: 'md' }}>
								Set New Password
							</Heading>
							<Text>
								If you entered a valid email address, you will receive your confirmation code there.
							</Text>
							<Text>Please check your email for your confirmation code and enter it below.</Text>
							<Stack spacing="5">
								<FormControl isRequired isInvalid={Boolean(formikConfirm.errors.confirmationCode)}>
									<FormLabel htmlFor="confirmationCode">Confirmation Code</FormLabel>
									<Input
										id="confirmationCode"
										type="text"
										name="confirmationCode"
										required
										value={formikConfirm.values.confirmationCode}
										onChange={formikConfirm.handleChange}
										onBlur={formikConfirm.handleBlur}
									/>
									{formikConfirm.errors.confirmationCode && (
										<FormErrorMessage>{formikConfirm.errors.confirmationCode}</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isRequired isInvalid={Boolean(formikConfirm.errors.password)}>
									<FormLabel htmlFor="password">New Password</FormLabel>
									<InputGroup>
										<InputRightElement>
											<IconButton
												variant="text"
												aria-label={showPassword ? 'Mask password' : 'Reveal password'}
												icon={showPassword ? <HiEyeOff /> : <HiEye />}
												onClick={onToggleShowPassword}
											/>
										</InputRightElement>
										<Input
											id="password"
											name="password"
											type={showPassword ? 'text' : 'password'}
											autoComplete="current-password"
											required
											onChange={formikConfirm.handleChange}
											onBlur={formikConfirm.handleBlur}
										/>
									</InputGroup>
									<Center mt={1}>
										<Progress
											colorScheme={pwStr < 66 ? 'red' : pwStr < 100 ? 'yellow' : 'green'}
											height="32px"
											value={pwStr}
											width={'100%'}
											aria-label="Password Strength"
										/>
										<Text
											pos={'absolute'}
											textShadow={'1px 1px 2px rgba(0,0,0,0.6)'}
											color={pwStr < 66 ? undefined : 'white'}
										>
											{!formikConfirm.values.password
												? 'No Password'
												: pwStr < 33
													? 'Too Weak'
													: pwStr < 66
														? 'Weak'
														: pwStr < 100
															? 'Medium'
															: 'Strong'}
										</Text>
									</Center>
									{formikConfirm.errors.password && (
										<FormErrorMessage>{formikConfirm.errors.password}</FormErrorMessage>
									)}
								</FormControl>

								<FormControl isRequired isInvalid={Boolean(formikConfirm.errors.confirmPassword)}>
									<FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
									<InputGroup>
										<InputRightElement>
											<IconButton
												variant="text"
												aria-label={showConfirmPassword ? 'Mask password' : 'Reveal password'}
												icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
												onClick={onToggleShowConfirmPassword}
											/>
										</InputRightElement>
										<Input
											id="confirmPassword"
											name="confirmPassword"
											type={showConfirmPassword ? 'text' : 'password'}
											autoComplete="current-password"
											required
											onChange={formikConfirm.handleChange}
											onBlur={formikConfirm.handleBlur}
										/>
									</InputGroup>
									{formikConfirm.errors.confirmPassword && (
										<FormErrorMessage>{formikConfirm.errors.confirmPassword}</FormErrorMessage>
									)}
								</FormControl>
								<Text fontSize={'sm'} textAlign={'left'} alignSelf={'flex-start'}>
									Create a password 8 to 25 characters long that includes at least 1 uppercase and 1
									lowercase letter, 1 number and 1 special character like an exclamation point or
									asterisk.
								</Text>
								<HStack justify="flex-end">
									<Button
										textTransform={'uppercase'}
										onClick={() => {
											toast.closeAll();
											formikConfirm.handleSubmit();
										}}
										isLoading={setNewPasswordLoading}
									>
										change password
									</Button>
								</HStack>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			)}
			{showSuccess && (
				<Box
					maxW="7xl"
					mx="auto"
					px={{ base: '4', md: '8', lg: '12' }}
					py={{ base: '6', md: '8', lg: '12' }}
				>
					<Stack spacing="8">
						<Stack spacing={{ base: '2', md: '3' }}>
							<Heading as={'h1'} fontSize={{ base: 'sm', md: 'md' }}>
								Your password has been updated!
							</Heading>
							<SignInButton />
						</Stack>
					</Stack>
				</Box>
			)}
			<FooterThreeColumn />
		</Box>
	);
});
export const AnimatedForgotPasswordPage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <ForgotPasswordPage ref={ref} />),
);
export default ForgotPasswordPage;
