import React from 'react';
import { motion } from 'framer-motion';
import { Box, Flex, Stack, VStack } from '@chakra-ui/react';
import { ScrollRestoration } from 'react-router-dom';
import {
	HowItWorksTrail,
	SidebarWhyJoin,
	TierBenefits,
	NavigationBar,
	FooterThreeColumn,
	useSharedAppStates,
	CartBar,
	PageTitle,
} from '@ebbo/pulse-uikit';

// REVIEW Move this to a config variable?
// REVIEW Ensure this is for max, verify mobile is not impacted.
const contentWidth = '1280px';

const HowItWorksPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user } = useSharedAppStates();
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="How It Works" />
			{/* // direction={'column'} justifyContent={'center'}> */}
			{/* 100% page width */}
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<Flex
				id="backgrounddivBeige"
				direction={'column'}
				justifyContent={'flex-start'}
				alignItems={'center'}
				backgroundImage={'/media/images/HIW_background_img_beige.jpg'}
				backgroundPosition={'bottom left'}
				backgroundSize={'cover'}
				backgroundRepeat={'no-repeat'}
				width={'100%'}
			>
				{!user && (
					<Stack
						id="columns"
						direction={{ base: 'column', xl: 'row' }}
						spacing={{ base: '12', xl: '16' }}
						maxW={contentWidth}
						width={'100%'}
						alignSelf={'center'}
						mb={'30px'}
					>
						<SidebarWhyJoin />
						<HowItWorksTrail />
					</Stack>
				)}
				{user && (
					<VStack>
						<HowItWorksTrail />
					</VStack>
				)}
			</Flex>
			<TierBenefits backgroundImageSrc="/media/images/chart-bg.webp" />
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedHowItWorksPage = motion.create(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <HowItWorksPage ref={ref} />),
);

export default HowItWorksPage;
